import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~5],
		"/(auth-admin)/admin": [~6,[2]],
		"/(auth-admin)/admin/feedback": [~7,[2]],
		"/(auth-admin)/admin/flags": [~8,[2]],
		"/(auth-admin)/admin/leagues": [~9,[2]],
		"/(auth-admin)/admin/questions": [~10,[2]],
		"/(auth-admin)/admin/questions/archive": [~12,[2]],
		"/(auth-admin)/admin/questions/archive/[year]-[month]-[day]": [~13,[2]],
		"/(auth-admin)/admin/questions/schedule": [~14,[2]],
		"/(auth-admin)/admin/questions/[id]": [~11,[2]],
		"/(auth-admin)/admin/reports": [15,[2,3]],
		"/(auth-admin)/admin/reports/active-users": [~16,[2,3]],
		"/(auth-admin)/admin/reports/participation-rates": [~17,[2,3]],
		"/(auth-admin)/admin/reports/question-ratings": [~18,[2,3]],
		"/(auth-admin)/admin/users": [~19,[2]],
		"/(auth-admin)/admin/users/send-reminder": [~23,[2]],
		"/(auth-admin)/admin/users/[id]": [~20,[2]],
		"/(auth-admin)/admin/users/[id]/answer/[question_id]": [~21,[2]],
		"/(auth-admin)/admin/users/[id]/answer/[question_id]/edit": [~22,[2]],
		"/archive": [~30],
		"/archive/[year]/[month]/[day]": [~31],
		"/(auth-user)/feedback": [~24,[4]],
		"/leaderboard": [~32],
		"/leaderboard/beta-results": [~33],
		"/leaderboard/streaks": [~34],
		"/leaderboard/streaks/beta-results": [~35],
		"/(auth-user)/leagues": [~25,[4]],
		"/login": [~36],
		"/logout": [37],
		"/privacy-statement": [38],
		"/register": [~39],
		"/register/complete": [40],
		"/reset-password": [41],
		"/reset-password/sent": [42],
		"/reset-password/update": [43],
		"/rules": [~44],
		"/(auth-user)/statistics": [~26,[4]],
		"/(auth-user)/statistics/answers": [~27,[4]],
		"/(auth-user)/statistics/global": [~28,[4]],
		"/unsubscribe/[uid]": [~46],
		"/(auth-user)/user/settings": [~29,[4]],
		"/u/[user_name]": [~45]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';