import * as Sentry from "@sentry/sveltekit"
import type { ClientInit, HandleClientError } from "@sveltejs/kit"
import { version } from "./version"
import { PUBLIC_ENVIRONMENT, PUBLIC_SENTRY_DSN, PUBLIC_SUPABASE_URL } from "$env/static/public"
import { getMessageForStatus } from "$helpers/errors"
import { uuidv4 } from "$helpers/utils"

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.25,
  integrations: [
    Sentry.replayIntegration({
      // Prefer a hosted worker script to avoid
      // having to allow worker-src blobs in csp
      workerUrl: "/sentry/worker.min.js"
    }),
    Sentry.browserTracingIntegration({
      shouldCreateSpanForRequest: (url) => {
        return !url.startsWith(`${PUBLIC_SUPABASE_URL}/rest`)
      },
    }),
  ],
  release: version,
  environment: PUBLIC_ENVIRONMENT,
  enabled: PUBLIC_ENVIRONMENT !== "development",
  ignoreErrors: [],
})

const errorsHandle: HandleClientError = ({ status }) => {
  const errorId = status >= 500 ? uuidv4() : undefined

  return {
    message: getMessageForStatus(status),
    errorId
  }
}

const init: ClientInit = async () => { }
const handleError = Sentry.handleErrorWithSentry(errorsHandle)

export { handleError, init }
